import logo from './images/logo-hannover-re.svg';
import styles from './css/hr-bootstrap-theme.css';

//import './App.css';
import Amplify from "aws-amplify";
import '@aws-amplify/ui-react/styles.css';
//import { withAuthenticator } from '@aws-amplify/ui-react';
//import { withOAuth } from 'aws-amplify-react';
import { Auth, API } from 'aws-amplify';
import { properties } from './config.js';
import React from 'react';


const currentConfig = Amplify.configure({
  //awsExports,
  Auth: {
    //storage: MyStorage,
    domain: properties.cognitoDomain,
    userPoolWebClientId: properties.appClientId,
    identityPoolId: properties.identityPoolId,
    identityPoolRegion: 'eu-central-1',
    userPoolId: properties.userPoolId,
    mandatorySignIn: false,
    region: 'eu-central-1',
    // Authorized scopes
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    // Callback URL
    redirectSignIn: properties.redirectSignIn,
    // Sign out URL
    redirectSignOut: properties.redirectSignOut,
    responseType: 'code',
    authenticationFlowType: 'ALLOW_REFRESH_TOKEN_AUTH',
    oauth: {
      // Domain name
      domain: properties.cognitoDomain,
      // Authorized scopes
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      // Callback URL
      redirectSignIn: properties.redirectSignIn,
      // Sign out URL
      redirectSignOut: properties.redirectSignOut,
      responseType: 'code',
      clientId: properties.appClientId,
    }
  },
  API: {
    endpoints: [
      {
        name: properties.endpointName,
        endpoint: properties.endpointUrl,
        service: "execute-api",
        region: "eu-central-1"
      },
    ]
  }
});

console.log(currentConfig);


console.log(Auth.currentAuthenticatedUser());

console.log(Auth.currentUserInfo());


function start(id) {
  API.get(properties.endpointName, "/start").then(response => {
    //this.setState(response);
    console.log(this.state);
  }).catch(error => {
    console.log("Error");
    console.log(error)
  });
}

function stop(id) {
  console.log(API)
  API.get(properties.endpointName, "/stop").then(response => {
    //this.setState(response);
    console.log(this.state);
  }).catch(error => {
    console.log("Error");
    console.log(error)
  });
}

function signOut() {
  console.log("Sign Out clicked!");
  Auth.signOut()
    .then(data => {
      window.location.reload();
    }
    )
    .catch(err => {
      console.log("err");
      console.log(err);
    }
    );
};

const config = Auth.configure();
console.log("config");
console.log(config);
const {
  domain,
  redirectSignIn,
  redirectSignOut,
  responseType } = config.oauth;

const clientId = config.userPoolWebClientId;
const url = 'https://' + domain + '/login?redirect_uri=' + redirectSignIn + '&response_type=' + responseType + '&client_id=' + clientId;

let current_session = Auth.currentSession();
console.log("current_session: ");
console.log(current_session);


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      response: null
    };
    this.count = 0;
    //this.check = this.check.bind(this);
    //this.tick();
    this.interval = setInterval(this.tick(), 5000);
  };

  componentDidMount() {
    Auth.currentUserCredentials().then(c => {
      console.log("currentUserCredentials");
      console.log(c);
      Auth.currentAuthenticatedUser()
      .then(user => {
          console.log(user);
          this.state.user = user.attributes.email;
          let accessToken = user.signInUserSession.accessToken;
          let exp = accessToken.payload.exp;
          // console.log("OPENID USER NAME:" + this.props.user.username);
          let username = user.username;
          // let fed_response = Auth.federatedSignIn('openid', { accessToken, expires_at: exp }, { name: username })
        })
        .catch(error => {
          
          //alert("Error authenticating user: " + error);
          console.log("Error authenticating user: " + error);
          window.location.assign(url)
        });
      });
      this.interval = setInterval(() => { this.tick() }, 5000);
    //if(this.interval !== null) clearInterval(this.interval);

  };

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;
  };

  is_authenticated() {
    console.log(this.state);
    if (this.state.user) {
      return this.state.user.length > 0
    };
    return false;
  }

  tick() {
    API.get("aar", "/check").then(response => {
      this.setState({
        response: response
      }
      );
      console.log("state=" );
      console.log(this.state);
    }).catch(error => {
      console.log("Error:");
      console.log(error);
    });
  }

  render() {
    
    let main_component;
    Auth.currentAuthenticatedUser().then(user => {
      this.state.user = user.attributes.email;
      //this.tick();
    }).catch({
    });

    //if (this.is_authenticated && this.state.workstations) {
    if (this.is_authenticated && this.state.response) {
      main_component = <EnvironmentsTable state={this.state} />
    }
    else {
      main_component = <AccessDenied />
    }
    return (
      <div className="container" id="root">
        <header className="header">
          <img src={logo} className="brand-logo" alt="Hannover Re" />
          <div className="application-name">{properties.title}</div>
        </header>
        <hr />
        <br /><br />

        {this.is_authenticated() ? 'Logged in as ' + this.state.user : ''}
        <br /> <br /> <br /><br />
        {main_component}


        <br />
        <br />
        <div className="form-group">
          <center>
            <button className="btn" onClick={ () => { signOut() } }>Sign Out</button>
          </center>
        </div>
        <div className="footer">
          <span>© 2022 Cloud Ninjas <i class="fas fa-user-ninja fa-spin"></i> Hannover Re</span>
          <ul className="nav float-right">
            <li>
              <a href="mailto:cwhr-selfservice@hannover-re.com">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    )

  }
};

function AccessDenied(props) {
  return (
    <div>
      <h2>Access Denied due to insufficient permissions.</h2>
      <h2>Please contact the Cloud-Team.</h2>
    </div>
  )
}

function EnvironmentsTable(props) {
  console.log("PROPS");
  console.log(props);
  return (
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>Stack</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr className="active">
          <th scope="row">REINS</th>
          <td className={props.state.response.reins == 'CREATE_COMPLETE' ? 'success' : 'danger'}>{props.state.response.reins}</td>
          <td>
            <button className="btn" onClick={() => { if (window.confirm('Are you sure you want to start the REINS environment?')) start() }}>start</button>
            &nbsp;
        <button className="btn" onClick={() => { if (window.confirm('Are you sure you want to stop the REINS environment?')) stop() }}>stop</button>
            &nbsp;
      </td>
        </tr>
      </tbody>
    </table>
  );
}


export default App;

