const properties_dev = {
    title: 'Application Archive REINS Self-service',
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
    cognitoDomain: 'selfservice.auth.eu-central-1.amazoncognito.com',
    userPoolId: 'eu-central-1_iX1S8VuXd',
    appClientId: '55e7it8kpno67unfro7mnk3dru', 
    identityPoolId: 'eu-central-1:0c2d24e6-a397-4514-9300-f54bb987136a',
    endpointName: "aar",
    endpointUrl: "https://aar-api.selfservice.hannover-re.cloud",
    user_group: "eu-central-1_U1MoOHHep_oktadev", 
};
const properties_prod = {
    title: 'Application Archive REINS Self-service',
    redirectSignIn: 'https://applicationarchive-reins.selfservice.hannover-re.cloud/',
    redirectSignOut: 'https://applicationarchive-reins.selfservice.hannover-re.cloud/',
    cognitoDomain: 'selfservice.auth.eu-central-1.amazoncognito.com',
    userPoolId: 'eu-central-1_iX1S8VuXd',
    appClientId: '55e7it8kpno67unfro7mnk3dru', 
    identityPoolId: 'eu-central-1:0c2d24e6-a397-4514-9300-f54bb987136a',
    endpointName: "aar",
    endpointUrl: "https://aar-api.selfservice.hannover-re.cloud",
    user_group: "eu-central-1_U1MoOHHep_oktadev", 
};

let properties = properties_prod;

export { properties };